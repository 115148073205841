.nav-items-sec {
  text-decoration: none;
}

.inside-video-sec {
  position: fixed;
  width: 100%;
  z-index: 999;
  background-color: rgb(46, 44, 44);
  height: 6em;
  display: grid;
  grid-template-columns: 24% 15% 9% 9% 9% 9% 9% 9%;
  justify-content: center;
  align-items: center;

  border-bottom: 1px solid rgb(253, 253, 253, 0.3);
  grid-template-rows: 100%;
  transition: 0.3s ease-in;
  font-family: "Oswald", sans-serif;
}
.dvs-header__trigger {
  cursor: pointer;
}
.sec-is-open {
  position: block;
  width: 100%;
  height: 100vh;

  display: flex;
  flex-direction: column;
  background-color: whitesmoke;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 80;
  transition: 0.3s ease-in;
  font-family: "Oswald", sans-serif;
  .nav-items-sec {
    display: block;
    z-index: 80;
    opacity: 1;
    border-bottom: 0;
    font-size: 2em;
    font-weight: bold;
    color: green;
    width: 100%;
    text-align: center;
    margin-left: 0;
  }
  .header-bg-sec {
    background-color: rgba(0, 0, 0, 1);
    border-bottom: none;
    z-index: 99;
  }
  .nav-items-sec:hover {
    color: orange;
    transition: 0.4s;
  }
  #logo-sec {
    position: absolute;
    top: 45%;
    left: 0%;
    img {
      width: 50%;
    }
  }
  .dvs-header__trigger {
    position: absolute;
    justify-self: center;
    align-self: flex-end;
    cursor: pointer;
  }
}
.header-bg-sec {
  background-color: rgba(0, 0, 0, 0.5);
  border-bottom: none;
  z-index: 99;
}
.header-bg-sec-two {
  background-color: whitesmoke;
  border-bottom: none;
  z-index: 99;
}
#logo-sec img{
  width: 18%;
}

@media (max-width: 1140px) {
  .nav-items-sec {
    margin-left: 4em;
    opacity: 1;
    z-index: 20;
    text-decoration: none;
    color: white;
    font-size: large;
  }

  .inside-video-sec {
    position: fixed;
    width: 100%;
    background-color: rgb(46, 44, 44);
    height: 6em;
    display: grid;
    text-align: center;
    grid-template-columns: 24% 0% 10% 10% 14.9% 10% 12% 10%;
    justify-content: center;
    align-items: center;

    border-bottom: 1px solid rgb(253, 253, 253, 0.3);
    grid-template-rows: 100%;
    transition: 0.3s ease-in;
    font-family: "Oswald", sans-serif;
  }

  .header-bg-sec {
    background-color: rgba(0, 0, 0, 0.5);
    border-bottom: none;
    z-index: 99;
  }
  .dvs-header__trigger {
    display: none;
  }
}
.nav-items-sec {
  display: none;
}
@media (max-width: 880px) {

  .dvs-header__trigger {
    display: block;
    position: absolute;
    top: 2.3em;
    right: 2em;
  }

  .nav-items-sec {
    display: none;
  }

  #logo-sec  {
    width: 80%;
  }

  .inside-video-sec {
    position: fixed;
    width: 100%;
    height: 6em;
    border-bottom: 1px solid rgb(253, 253, 253, 0.3);
    display: grid;
    grid-template-columns: 24% 15% 9% 9% 9% 9% 9% 9%;
    justify-content: center;
    align-items: center;

    transition: 0.3s ease-in;
    font-family: "Oswald", sans-serif;
  }

  .sec-is-open {
    position: block;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: whitesmoke;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 80;
    transition: 0.3s ease-in;
    font-family: "Oswald", sans-serif;
    .nav-items-sec {
      display: block;
      z-index: 80;
      opacity: 1;
      border-bottom: 0;
      font-size: 2em;
      font-weight: bold;
      color: green;
      width: 100%;
      text-align: center;
      margin-left: 0;
    }
    #logo-sec {
      display: none;
    }
    .dvs-header__trigger {
      position: absolute;
      top: 3em;
      right: 2em;
      align-self: flex-end;
    }
  }
}
@media (max-width: 880px) {
 
  
 

  #logo-sec img{
    margin-left: 1em;
    width: 15vw;
  }

  



}
