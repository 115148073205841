* {
  font-family: "Oswald", sans-serif;
}

.about-us {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#about-img {
  width: 100%;
  height: 30em;

}

#about-sec-one {
  display: flex;
  width: 70%;
  margin-top: 20vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#about-title {
  font-family: "Oswald", sans-serif;
  font-weight: bold;
  font-size: 5em;
}
#about-cont {
  width: 75%;
  margin-top: 2em;
  height: 25em;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

#about-p {
  font-family: "Oswald", sans-serif;
  width: 100%;
  height: 40%;
  text-align: center;
}

#mission-part {
  margin-top: 10em;
  width: 100%;
  height: 25em;
  background-color: #e0efe7;
  display: flex;
  color: green;

  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 10em;
}

.video-container {
  width: 100%;
  margin-top: 10em;
  position: relative;
  height: 30em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1 {
    color: white;
    text-align: center;
    font-size: 5em;
    width: 60%;
    z-index: 22;
  }
}

.video-container video {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  z-index: 0;
}
.video-container img {
  width: 100%;
  height: 80%;
  position: absolute;
  object-fit: cover;
  z-index: 0;
}
#about-vid-background {
  width: 100%;
  position: absolute;
  height: 100%;
  background-color: rgba(40, 43, 40, 0.3);
  z-index: 4;
}

/* Just styling the content of the div, the *magic* in the previous rules */
.video-container .caption {
  z-index: 6;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #dc0000;
  padding: 0;
}
#about-img-blur {
  display: none;
}

@media (max-width: 1140px) {
  .about-us {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  #about-img {
    width: 45%;
    height: 40em;
  }

  #about-sec-one {
    display: flex;
    width: 90%;
    margin-top: 20vh;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }

  #about-title {
    position: absolute;
    top: 20vh;
    z-index: 1;
    height: 50%;
    font-family: "Oswald", sans-serif;
    font-weight: bold;
    left: 35vw;
    font-size: 7em;
  }
  #about-cont {
    width: 45%;
    height: 40em;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  #about-p {
    font-family: "Oswald", sans-serif;
    width: 100%;
    height: 40%;
    text-align: center;
  }

  #mission-part {
    margin-top: 10em;
    width: 100%;
    height: 25em;
    background-color: #e0efe7;
    display: flex;
    color: green;

    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 10em;
  }

  .video-container {
    width: 100%;
    margin-top: 10em;
    position: relative;
    height: 30em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h1 {
      color: white;
      text-align: center;
      font-size: 5em;
      width: 60%;
      z-index: 22;
    }
  }

  .video-container video {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    z-index: 0;
  }
  .video-container img {
    width: 100%;
    height: 80%;
    position: absolute;
    object-fit: cover;
    z-index: 0;
  }
  #about-vid-background {
    width: 100%;
    position: absolute;
    height: 100%;
    background-color: rgba(40, 43, 40, 0.3);
    z-index: 4;
  }

  /* Just styling the content of the div, the *magic* in the previous rules */
  .video-container .caption {
    z-index: 6;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #dc0000;
    padding: 10px;
  }
  #about-img-blur {
    display: none;
  }
}

@media (max-width: 880px) {
  .about-us {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  #about-img {
    width: 100%;
    height: 15em;
    position: absolute;
    top: 6em;
    z-index: 1;
  }

  #about-sec-one {
    display: flex;
    width: 100%;
    margin-top: 12vh;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 3em 0;
  }

  #about-img-blur {
    position: absolute;
    width: 100%;
    height: 20em;
    top: 8em;
    background-color: rgba(40, 43, 40, 0.2);
    z-index: 10;
  }

  #about-title {
    position: absolute;
    text-align: center;
    width: 80%;
    top: 50vh;
    z-index: 50;

    color: white;

    font-family: "Oswald", sans-serif;
    font-weight: bold;
    left: 1rem;
    font-size: 3.5em;
  }
  #about-cont {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  #about-p {
    font-family: "Oswald", sans-serif;
    width: 90%;
    margin-top: 23em;

    text-align: center;
  }

  #mission-part {
    width: 100%;
    height: 100%;
    background-color: #e0efe7;
    display: flex;
    color: green;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-top: 2em;
    margin-bottom: 2em;
    padding-top: 2em;
    padding-bottom: 2em;
    p {
      text-align: center;
    }
  }

  .video-container {
    width: 100%;

    position: relative;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h1 {
      color: white;
      text-align: center;
      font-size: 2em;
      width: 100%;
      z-index: 22;
    }
  }

  .video-container video {
    width: 100%;
    height: 80%;
    position: absolute;
    object-fit: cover;
    z-index: 0;
  }
  .video-container img {
    width: 100%;
    height: 60%;
    position: absolute;
    object-fit: cover;
    z-index: 0;
  }
  #about-vid-background {
    width: 100%;
    position: absolute;
    height: 60%;
    background-color: rgba(40, 43, 40, 0.3);
    z-index: 4;
  }

  /* Just styling the content of the div, the *magic* in the previous rules */
  .video-container .caption {
    z-index: 6;
    width: 90%;
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #dc0000;
    padding: 0;
  }
}
