#navbar-body {
  width: 100%;
  height: 100vh;
  top: 0;
  z-index: 100;
}
#background-video {
  height: 90%;
  width: 100%;
  float: left;
  top: 0;
  object-fit: cover;
  padding: none;
}
.dvs-header__trigger {
  position: absolute;
  top: 2.5em;
  right: 2em;
  align-self: flex-end;
}

#vid-btn {
  
  background-color: green;
  margin-top: 3em;
  display: flex;
  cursor: pointer;
  font-family: "Oswald", sans-serif;
  font-size: larger;
  flex-direction: row;
  justify-content: space-between;
  gap: 0.4rem;
  padding: 1rem;
  align-self: left;
  align-items: center;
  text-decoration: none;
  color: white;
}
.inside-video {
  position: fixed;
  width: 100%;
  height: 6em;
  border-bottom: 1px solid rgb(253, 253, 253, 0.3);
  display: grid;
  grid-template-columns: 24% 15% 9% 9% 9% 9% 9% 9%;
  justify-content: center;
  align-items: center;
  z-index: 20;
  grid-template-rows: 100%;
  transition: 0.3s ease-in;
  font-family: "Oswald", sans-serif;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 90%;
  background-color: rgba(40, 43, 40, 0.4);
  display: flex;
  z-index: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#overlay-p {
  display: flex;
  width: 60%;
  height: 60%;
  margin-right: 15%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: white;
  z-index: 1;
  font-family: "Roboto", sans-serif;
  #overlay-title {
    font-size: 3em;
    font-weight: bold;
  }
}

#logo img {
  width: 18%;
}

.nav-items {
  display: none;
}

.is-open {
  position: block;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: whitesmoke;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: 0.3s ease-in;
  font-family: "Oswald", sans-serif;
  .nav-items {
    display: block;
    opacity: 1;
    border-bottom: 0;
    font-weight: bold;
    font-size: 2em;
    color: green;
    width: 40%;
    text-decoration: none;
    border: none;

    text-align: center;
    margin-left: 0;
  }

  .nav-items:hover {
    color: orange;
    transition: 0.4s;
  }
  #home {
    margin-top: 2em;
  }
  #logo {
    position: absolute;
    top: 45%;
    left: 0%;
    img {
      width: 50%;
    }
  }
}

@media (max-width: 1600px) {

  #overlay-p {
    display: flex;
    width: 80%;
    height: 30%;
    margin-right: 15%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    color: white;
    z-index: 1;
    font-family: "Roboto", sans-serif;
    #overlay-title {
      font-size: 3em;
      font-weight: bold;
    }
  }
}



@media (max-width: 880px) {
  #navbar-body {
    width: 100%;
    height: 30em;
    z-index: 9;
  }

  #logo {
    margin-left: 1em;
  }

  #background-video {
    height: 30em;
    width: 100%;
    object-fit: fill;
    float: left;
    top: 0;
    object-fit: cover;
    padding: none;
  }

  .dvs-header__trigger {
    display: block;
    position: absolute;
    top: 2.5;
    right: 2em;
  }

  .nav-items {
    display: none;
  }

  #vid-btn {
    background-color: green;
  margin-top: 3em;
  display: flex;
  cursor: pointer;
  font-family: "Oswald", sans-serif;
  font-size: larger;
  flex-direction: row;
  justify-content: space-between;
  gap: 0.4rem;
  font-size: 0.8rem;
  padding: 0.4rem;
  align-self: left;
  align-items: center;
  text-decoration: none;
  color: white;
  }
  .inside-video {
    position: fixed;
    width: 100%;
    height: 6em;
    border-bottom: 1px solid rgb(253, 253, 253, 0.3);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 5;
    transition: 0.3s ease-in;
    font-family: "Oswald", sans-serif;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 30em;
    z-index: 1;
    background-color: rgba(40, 43, 40, 0.4);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  #overlay-p {
    display: flex;
    width: 80%;
    margin-top: 4em;
    height: 100%;
    margin-right: 15%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    color: white;
    font-family: "Roboto", sans-serif;
    #overlay-title {
      font-size: 1.5em;
      font-weight: bold;
    }
  }
}

.is-open {
  position: block;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: whitesmoke;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: 0.3s ease-in;
  font-family: "Oswald", sans-serif;
  .nav-items {
    display: block;
    z-index: 80;
    opacity: 1;
    border-bottom: 0;
    font-size: 2em;
    font-weight: bold;
    color: green;
    width: 100%;
    text-align: center;
    margin-left: 0;
}
#logo{
  display: none;
}
.header-bg {
  background-color: whitesmoke;
  border-bottom: none;
}
}


.header-bg {
  background-color: rgba(0, 0, 0, 0.5);
  border-bottom: none;
}
