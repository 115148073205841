#application-container{
 width: 100%;
 background-color: #f9f9f9;
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 padding-bottom: 5em;
 z-index: 10;
}

#applications-page-two {
  width: 95%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  li{
    text-align: left;
  }
  
}
#item2{
  width: 100%;

 
  #item2-img{
    width: 100%;
    height: 30%;
    object-fit: fill;
  }
}
#app-vid{
  width: 40%;
}

#app-cont {
  width: 80%;
  margin-top: 10em;
  margin-bottom: 4em;
  padding: 2em;
  padding-top: 4em;
  background-color: white;
  h2 {
    text-align: center;
  }
  h1 {
    text-align: center;
  }
 
  #app-img {
    text-align: center;
    margin-top: 2em;
    
    width: 40%;
    margin-bottom: 2em;
  }
}

@media (max-width: 1140px) {
  #applications-page-two {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    z-index: 2;
    background-color: #f9f9f9;
  }

  #app-cont {
    width: 70%;
    margin-top: 10em;
   
    padding-top: 4em;
    background-color: white;
    h2 {
      text-align: center;
    }
    h1 {
      text-align: center;
    }
 
    #app-img {
      text-align: center;
      margin-left: 0;
      width: 100%;
      margin-top: 2em;
      margin-bottom: 2em;
    }
  }
}

@media (max-width: 880px) {
  #applications {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
margin-bottom: 2em;
text-align: center;

    background-color: #f9f9f9;
  }
  #application-container{
    width: 100%;
    background-color: #f9f9f9;
    display: flex;
    flex-direction: column;
    justify-content: baseline;
    align-items: center;
    padding-bottom: 5em;
    z-index: 1;
  
   }
   
  #applications-page-two {
    z-index: 1;
    height: fit-content;
    width: 100%;
    height: fit-content;
    display: flex;
    height: 80%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 9em;
    #app-gif{
      width: 95%;
    }

    li{
      text-align: left;
    }
    
  }
  

  #app-cont {
    width: 90%;
  padding: 0;
    margin: 0;
    padding-bottom: 4em;
    background-color: white;
    h2 {
      text-align: center;
    }
    h1 {
      text-align: center;
    }
 
    #app-img {
      text-align: center;

      margin-top: 2em;
      margin-bottom: 2em;
    }
  }
}
